import Alpine from "alpinejs";
import Splide from "@splidejs/splide";
import Cookies from "js-cookie";
import Pristine from "pristinejs";

import "./styles/tailwind.css";

var pristine;
window.onload = function () {
  pristine = new Pristine(document.querySelector("#contactform"), {
    errorTextClass: "error-message",
  });
};

const slide1 = new Splide("#slide-1", {
  type: "loop",
  focus: "center",
  autoWidth: true,
  autoHeight: true,
  autoplay: true,
  interval: 2000,
  speed: 18000,
  drag: false,
  easing: "linear",
  arrows: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  pagination: false,
});
slide1.mount();
const slide2 = new Splide("#slide-2", {
  type: "loop",
  focus: "center",
  autoWidth: true,
  autoHeight: true,
  autoplay: true,
  interval: 2000,
  speed: 18000,
  drag: false,
  easing: "linear",
  arrows: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  pagination: false,
  direction: "rtl",
});
slide2.mount();

document.addEventListener("alpine:init", () => {
  Alpine.data("configCookies", function () {
    return {
      showBanner: true,
      cookieName: "Cacco Trial Offers",
      expires: 30,
      setCookies() {
        Cookies.set(this.cookieName, true, { expires: this.expires });
        this.showBanner = false;
      },
      getCookies() {
        return Cookies.get(this.cookieName);
      },
      checkCookies() {
        this.showBanner = this.getCookies() !== undefined ? false : true;
      },
    };
  });

  Alpine.data("forms", function () {
    return {
      loading: false,
      submit: function () {
        var valid = pristine.validate();
        if (valid) {
          this.loading = true;
          let formData = new FormData(document.querySelector("#contactform"));
          // for (const [key, value] of formData) {
          //   console.log('»', key, value);
          // }
          fetch("./form.php", {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              if (response.ok) {
                this.loading = false;
                this.isSuccess = true;
                document.querySelector("#contactform").reset();
                window.location.href = "./thanks";
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          window.location.href = "#contact-us";
        }
      },
    };
  });
});

Alpine.start();
